import { LinearProgressProps } from '@material-ui/core';
import StyledProgressBar from './ProgressBar.styles';

export interface LocalProps extends LinearProgressProps {
  /** Bar height value to override MuiLinearProgress-root(v4) */
  height?: number;
  /** Bar progress value */
  value?: number;
  /** Bar shape types */
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query' | undefined;
}

const ProgressBar = ({ height, ...props }: LocalProps) => (
  <StyledProgressBar height={height} {...props} />
);

export default ProgressBar;
