import React, { useState, useContext, useEffect, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import Grid from 'src/components/Grid';
import ListItem from 'src/components/ListItem';
import UnorderList from 'src/components/UnorderList';
import TimeList from './TimeList';
import PopOver from '../PopOver';
import {
  LIST_DAYS,
  DAY_PROPERTY,
  TABLE_MIN_WIDTH,
  STUDY_ITEMS_STORAGE,
} from 'src/constants';
import {
  StyledCell,
  StyledCursor,
  StyledDayLabel,
  StyledDragBox,
  StyledIconInCell,
  StyledHiddenTouchArea,
} from './TimeTable.styles';
import { StudySchedule } from 'src/types';
import {
  dragButton,
  getAssignedStudy,
  initMobileView,
  manageScroll,
  onTouchResize,
  setHours,
  setSchedule,
  setScheduleActive,
} from 'src/utils';
import { useIsMobile } from 'src/hooks';
import { TimeTableContext, PlannerContext, AppPropsContext } from 'src/context';
import useLocalStorage from 'src/hooks/use-localStorage';

const TimeTable = () => {
  const { addHours } = useContext(PlannerContext);
  const { studyTypes, onChangeStudyPlan } = useContext(AppPropsContext);
  const { isWrap, slotSize } = useContext(TimeTableContext);
  const [anchorEl, setAnchorEl] =
    useState<EventTarget | (EventTarget & Element) | null>(null);
  const [openPopOver, setOpenPopOver] = useState<boolean>(false);
  const [scheduleId, setScheduleId] = useState<string>('');
  const [isInit, setIsInit] = useState<boolean>(false);
  const [storedStudyItems, setStoredStudyItems] =
    useLocalStorage(STUDY_ITEMS_STORAGE);
  const isTableVisible = useIsMobile(TABLE_MIN_WIDTH);

  useEffect(() => {
    if (storedStudyItems) {
      storedStudyItems.map((study: StudySchedule) => {
        setSchedule(study.id, study.task);
        return setHours(`${study.id}-grid`, study.size);
      });
      setIsInit(!isInit);
    }
    if (isTableVisible) {
      initMobileView();
    }
  }, []);

  const onClickSchedule = useCallback(
    (e: Event | React.MouseEvent, targetId: string) => {
      e.stopPropagation();
      setScheduleId(targetId);
      setAnchorEl(e.currentTarget);
      setOpenPopOver(true);
      setScheduleActive(`${targetId}-grid`, true);
    },
    []
  );

  const onUpdateSchedule = (hours: number, schedule?: any) => {
    if (schedule) {
      setStoredStudyItems(schedule);
    }
    return addHours(hours);
  };

  return (
    <>
      <TimeList />
      {DAY_PROPERTY.map((mainDay, index) => {
        return (
          <Grid
            item
            key={index}
            data-day-column={mainDay.day}
            id={`${mainDay.day}-ul`}
            md
            sm
            xs
          >
            <StyledDayLabel>{mainDay.label}</StyledDayLabel>
            <UnorderList>
              {LIST_DAYS(mainDay.day).map((day, index) => {
                const getStudy = getAssignedStudy(day.id);
                const targetIcon = studyTypes.filter(
                  (item) => item.value === getStudy
                );
                if (!targetIcon[0]) {
                  window.location.reload();
                }
                return (
                  <ListItem
                    key={index}
                    id={day.id}
                    type={'day'}
                    studyItems={studyTypes}
                    data-activity-id={day.activity}
                    onMouseDown={(e) => onClickSchedule(e, day.id)}
                    slotSize={slotSize}
                  >
                    <StyledCell item id={`${day.id}-grid`}>
                      <StyledIconInCell
                        icon={targetIcon[0].icon}
                        iconcolor={targetIcon[0].iconColor}
                        type={getStudy}
                      />
                    </StyledCell>
                    {!dragButton(day.id) && (
                      <StyledDragBox
                        onTouchStart={(e: React.TouchEvent) => {
                          manageScroll('hidden');
                          onTouchResize({
                            e,
                            target: `${day.id}-grid`,
                            parent: `${day.id}`,
                            onChange: onUpdateSchedule,
                            onCallback: onChangeStudyPlan,
                          });
                        }}
                      >
                        <StyledCursor
                          id={`${day.id}-grid`}
                          component={IconButton}
                          wrap={isWrap ? 'top' : 'baseline'}
                          onMouseDown={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            onTouchResize({
                              e,
                              target: `${day.id}-grid`,
                              parent: `${day.id}`,
                              onChange: onUpdateSchedule,
                              onCallback: onChangeStudyPlan,
                            });
                          }}
                        />
                        <StyledHiddenTouchArea component={Grid} />
                      </StyledDragBox>
                    )}
                  </ListItem>
                );
              })}
            </UnorderList>
          </Grid>
        );
      })}
      <PopOver
        id={scheduleId}
        assignedStudy={getAssignedStudy(scheduleId)!}
        disableRestoreFocus={true}
        hideBackdrop={true}
        studyList={studyTypes}
        open={openPopOver}
        onHourChange={onUpdateSchedule}
        onClose={() => {
          setScheduleActive(`${scheduleId}-grid`, false);
          setOpenPopOver(false);
        }}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default TimeTable;
