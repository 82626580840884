import { useState } from 'react';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledExpandableIcon,
  StyledTitle,
} from './ReadMore.style';
import ReadMoreContents from './ReadMoreContents';
export default function ReadMore() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <StyledAccordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <StyledAccordionSummary
        expandIcon={<StyledExpandableIcon />}
        aria-controls='panel1bh-content'
        id='panel1bh-header'
        style={{ padding: '0px' }}
      >
        <StyledTitle id='panel1bh-child' variant='h6' display='initial'>
          Read more about the study types
        </StyledTitle>
      </StyledAccordionSummary>
      <ReadMoreContents />
    </StyledAccordion>
  );
}
