export const DEFAULT_STUDY_TYPES_PROP = [
  {
    color: '#efefef',
    icon: '',
    iconColor: '',
    label: 'Free',
    value: 'free',
    desc: '',
  },
  {
    color: '#BAD5FF',
    icon: 'LocalLibraryOutlined',
    iconColor: '#000000',
    label: 'Study Guide reading',
    value: 'studyGuideReading',
    desc: 'Learn and read the study guide modules',
    link: 'https://www.cpaaustralia.com.au/become-a-cpa#learn',
  },
  {
    color: '#6AA2EB',
    icon: 'LibraryBooksOutlined',
    iconColor: '#000000',
    label: 'Additional reading',
    value: 'additionalReading',
    desc: 'Further study to support the module readings, including weekly webinars to summarise and consolidate what you’ve learnt',
    link: 'https://www.cpaaustralia.com.au/become-a-cpa/about-the-cpa-program',
  },
  {
    color: '#0A5CC7',
    icon: 'SettingsBackupRestoreOutlined',
    iconColor: '#ffffff',
    label: 'Revision',
    value: 'revision',
    desc: 'Test and apply the knowledge you’ve learnt, work through the module’s quizzes and attempt the mid-semester practice test',
    link: 'https://www.cpaaustralia.com.au/become-a-cpa/starting-the-cpa-program/membership-pathways-and-arrangements',
  },
  {
    color: '#084697',
    icon: 'DescriptionOutlined',
    iconColor: '#ffffff',
    label: 'Exam preparation',
    value: 'examPreparation',
    desc: 'Preparatory study for the final exam, including working through the practice exams available in Guided Learning',
  },
];

export const DEFAULT_TIMETABLE_PROP = {
  height: 45,
  lineHeight: 45,
  width: 100,
  margin: 5,
};
