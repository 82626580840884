import { FC, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { AppPropsContext } from 'src/context';

type Props = { children?: React.ReactNode };
export const ThemeGenerator: FC<Props> = ({ children }) => {
  const { theme } = useContext(AppPropsContext);
  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </StyledThemeProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
};
