import { useContext } from 'react';
import { AccordionDetails, Link } from '@material-ui/core';
import { AppPropsContext } from 'src/context';
import Typography from 'src/components/Typography';
import Grid from 'src/components/Grid';
import { StyledAccordionTitle } from './ReadMore.style';
import { StudyItem } from 'src/types';
export default function ReadMoreContents() {
  const { studyTypes } = useContext(AppPropsContext);
  const studyList = studyTypes.filter((study) => study.value !== 'free');
  return (
    <AccordionDetails id='panel1bh-details' style={{ display: 'block' }}>
      <Grid container>
        {studyList.map((study: StudyItem, index: number) => {
          return (
            <Grid container key={index} mb={20}>
              <Grid item md={3} sm={2} xs={12}>
                <StyledAccordionTitle variant='body1' display='initial'>
                  {study.link && study.link !== '' ? (
                    <Link
                      onClick={() => study.link && window.open(study.link)}
                      color='inherit'
                    >
                      {study.label}
                    </Link>
                  ) : (
                    study.label
                  )}
                </StyledAccordionTitle>
              </Grid>
              <Grid item md={9} sm={10} xs={12}>
                <Typography variant='body2' display='initial'>
                  {study.desc}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </AccordionDetails>
  );
}
