import { useEffect, useState } from 'react';

const getIsMobile = (size: number) => window.innerWidth < size;

export function useIsMobile(size: number) {
  const [isMobile, setIsMobile] = useState(getIsMobile(size));
  useEffect(() => {
    const handleResize = () => setIsMobile(getIsMobile(size));
    window.addEventListener('resize', handleResize, { passive: false });
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}
