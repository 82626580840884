import {
  DEFAULT_EMPTY_SLOT_VALUE,
  DEFAULT_SUBJECT_NUM,
  SUBJECT_STORAGE,
  STUDY_HOURS_STORAGE,
  STUDY_ITEMS_STORAGE,
} from 'src/constants';
import { StudyItem, StudySchedule } from 'src/types';

import { DEFAULT_STUDY_TYPES_PROP } from 'src/constants';
import { StudyTypes } from 'src/types';

export const initStudyTypesProp = (newProp: StudyTypes) => {
  let propToBeAssigned = [];
  const hasFreeItem =
    newProp.filter((study) => study.value === DEFAULT_EMPTY_SLOT_VALUE).length >
    0;
  if (!hasFreeItem) {
    const freeItem = DEFAULT_STUDY_TYPES_PROP.filter(
      (study) => study.value === DEFAULT_EMPTY_SLOT_VALUE
    );
    propToBeAssigned = [...freeItem, ...newProp];
  } else {
    propToBeAssigned = newProp;
  }
  return propToBeAssigned;
};

export const hasStudyTypeUpdated = (
  storedTypes: Array<StudySchedule>,
  newTypes: Array<StudyItem>
) => {
  const storedStudyTypes = storedTypes.map((type: StudySchedule) => type.task);
  const newStudyTypes = newTypes.map((type: StudyItem) => type.value);
  let hasNewStudyItem = false;
  storedStudyTypes.map((item) => {
    return (hasNewStudyItem = !newStudyTypes.includes(item));
  });
  return hasNewStudyItem;
};

export const resetLocalStorage = () => {
  window.localStorage.setItem(
    SUBJECT_STORAGE,
    JSON.stringify(DEFAULT_SUBJECT_NUM)
  );
  window.localStorage.setItem(STUDY_HOURS_STORAGE, JSON.stringify(0));
  window.localStorage.setItem(STUDY_ITEMS_STORAGE, '');
};
