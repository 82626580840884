const getInitialStorageValue = (storageKey: string) => {
  try {
    const item = window.localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return null;
  }
};

export default function useLocalStorage(storageKey: string) {
  const storedValue = getInitialStorageValue(storageKey);
  const setValue = (value: {}) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
  };
  return [storedValue, setValue];
}
