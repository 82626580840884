import { createTheme } from '@material-ui/core/styles';

export const mockStudyTypeProps = [
  {
    color: '#50a150',
    icon: 'AllInboxOutlined',
    iconColor: '#ffffff',
    label: 'Sample Study Type 01',
    value: 'sampleStudyType01',
    desc: 'Overriden study type 01',
  },
  {
    color: '#de5b6d',
    icon: 'BallotOutlined',
    iconColor: '#ffffff',
    label: 'Sample Study Type 02',
    value: 'sampleStudyType02',
    desc: 'Study for exams',
    link: 'http://www.google.com.au',
  },
];

export const mockCallback = (data: any) => {
  console.log('data - ', data);
};

export const mockTheme = createTheme({
  palette: {
    primary: {
      main: '#5d00ff',
    },
    secondary: {
      main: '#0ba0ea',
    },
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont'].join(','),
  },
});
