import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export default styled((props) => <Grid {...props} />)`
  ${(props) =>
    props.mb !== undefined &&
    `
    margin-bottom: ${props.mb}px;
  `}
  ${(props) =>
    props.mt !== undefined &&
    `
    margin-top: ${props.mt}px;
  `}
`;
