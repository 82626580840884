import {
  cleanUpSlotSize,
  getElement,
  resizeMobileSchedule,
} from '../elements/element-helper';
import {
  getScheduleMaxSize,
  getAllStudyHours,
  getAllStudyItems,
  setStudyActivity,
} from '../scheduler/scheduler-helper';
import {
  DEFAULT_TIMESLOT_HEIGHT,
  DEFAULT_TIMESLOT_ZINDEX,
} from 'src/constants';
import { FuncResizeSchedule } from 'src/types';

export const manageScroll = (value: 'hidden' | 'scroll') => {
  document.body.style.overflow = value;
};

export const initMobileView = () => {
  document.documentElement.style.overflow = 'hidden';
  document.documentElement.style.height = '100%';
  document.documentElement.style.width = '100%';
  document.documentElement.style.position = 'fixed';
  document.body.style.height = '100%';
  document.body.style.width = '100%';
  document.body.style.overflow = 'scroll';
  document.body.style.touchAction = 'pan-x pan-y';
};

export const initDragSetting = (
  e: React.TouchEvent | React.MouseEvent,
  target: HTMLElement
) => {
  const { maxHeight } = getScheduleMaxSize(target.id);
  target.style.position = 'relative';
  target.style.minHeight = `${DEFAULT_TIMESLOT_HEIGHT}px`;
  target.style.zIndex = `${DEFAULT_TIMESLOT_ZINDEX}`;
  target.style.maxHeight = maxHeight;
  const currentHeight =
    target.style.height.split('px')[0] || `${DEFAULT_TIMESLOT_HEIGHT}`;
  let mouseY;
  if (window.TouchEvent && e.nativeEvent instanceof TouchEvent) {
    mouseY = e.nativeEvent.targetTouches[0].pageY;
  }
  if (e.nativeEvent instanceof MouseEvent) {
    mouseY = e.nativeEvent.pageY;
  }

  return {
    currentHeight,
    mouseY,
  };
};

export const onTouchResize = ({
  e,
  target,
  parent,
  onChange,
  onCallback,
}: FuncResizeSchedule) => {
  const targetElement = getElement(target);
  const monitoringElement = getElement(parent);
  const isMobile = window.TouchEvent && e.nativeEvent instanceof TouchEvent;

  if (targetElement && monitoringElement) {
    const { currentHeight, mouseY } = initDragSetting(e, targetElement);
    const initAssignedHours = Math.floor(
      (currentHeight === 'inherit'
        ? DEFAULT_TIMESLOT_HEIGHT
        : Number(currentHeight)) / DEFAULT_TIMESLOT_HEIGHT
    );
    const resize = (e: TouchEvent | MouseEvent) => {
      isMobile && manageScroll('hidden');
      if (currentHeight && mouseY) {
        resizeMobileSchedule({ e, target, currentHeight, mouseY });
      }
    };

    const stopResize = () => {
      document.removeEventListener(
        isMobile ? 'touchmove' : 'mousemove',
        resize
      );
      document.removeEventListener(isMobile ? 'touchend' : 'mouseup', resize);
      document.removeEventListener(
        isMobile ? 'touchend' : 'mouseup',
        stopResize
      );
      isMobile && manageScroll('scroll');
      cleanUpSlotSize(targetElement);
      const totalStudyHours = getAllStudyHours();
      const totalStudySchedule = getAllStudyItems();
      onChange(totalStudyHours, totalStudySchedule);
      onCallback(setStudyActivity(targetElement, initAssignedHours));
    };

    document.addEventListener(isMobile ? 'touchmove' : 'mousemove', resize, {
      passive: false,
    });

    document.addEventListener(
      window.TouchEvent && e.nativeEvent instanceof TouchEvent
        ? 'touchend'
        : 'mouseup',
      stopResize,
      {
        passive: false,
      }
    );
  }
};
