import Slider from '@material-ui/core/Slider';
import styled from 'styled-components';

export default styled((props) => <Slider {...props} />)`
  ${(props) => {
    const { height, thumbsize } = props;
    const thumbPosition = (thumbsize - height) / 2;
    return `
    &.MuiSlider-root {
        border-radius: 0;
        .MuiSlider-rail {
            height: ${height !== undefined ? `${height}px;` : '10px;'}
        }
        .MuiSlider-track {
            height: ${height !== undefined ? `${height}px;` : '10px;'}
        }
        .MuiSlider-thumb {
            margin-top: -${thumbPosition}px;
            margin-left: -38px;
            height: ${thumbsize !== undefined ? `${thumbsize}px;` : '20px;'}
            width: ${thumbsize !== undefined ? `${thumbsize}px;` : '20px;'}
        }
      }
    `;
  }}
`;
