import React, { RefObject } from 'react';
import { Button } from '@material-ui/core';
import Grid from 'src/components/Grid';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { StyledContainer } from './TableScroller.styles';

type TableSchedulerProps = {
  target: RefObject<HTMLInputElement>;
};

const TableScroller = (props: TableSchedulerProps) => {
  const scroll = (scrollOffset: number) => {
    if (props.target.current !== null || props.target.current !== undefined) {
      props.target.current!.scrollLeft += scrollOffset;
    }
  };

  return (
    <StyledContainer mt={3} container>
      <Grid id='scrollLeft'>
        <Button variant='outlined' onClick={() => scroll(-250)}>
          <KeyboardArrowLeftIcon />
        </Button>
      </Grid>
      <Grid>
        <Button id='scrollRight' variant='outlined' onClick={() => scroll(250)}>
          <KeyboardArrowRightIcon />
        </Button>
      </Grid>
    </StyledContainer>
  );
};
export default TableScroller;
