import React from 'react';
import StyledGrid from './Grid.styles';
import { GridProps } from '@material-ui/core';

export interface LocalProps extends GridProps {
  children: React.ReactNode;
  /** Assign additional margin at top (px) */
  mb?: number;
  /** Assign additional margin at bottom (px) */
  mt?: number;
}

const Grid = ({ children, mb, mt, ...gridProps }: LocalProps) => (
  <StyledGrid mb={mb} mt={mt} {...gridProps}>
    {children}
  </StyledGrid>
);

export default Grid;
