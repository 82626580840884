import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Select,
} from '@material-ui/core';
import Grid from 'src/components/Grid';
import styled from 'styled-components';

export const StyledCornerBox = styled((props) => <Grid {...props} />)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledPaper = styled((props) => <Grid {...props} />)`
  padding: 5px;
  margin: 5px;
  min-width: 250px;
  min-height: 80px;
  max-width: 300px;
`;

export const StyledIconButton = styled((props) => <IconButton {...props} />)`
  padding: 3px;
  font-size: 0.3em;
`;

export const StyledInput = styled((props) => <InputLabel {...props} />)`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: -10px;
`;
export const StyledSelect = styled((props) => <Select {...props} />)`
  width: 200px;
`;

export const StyledButton = styled((props) => <Button {...props} />)`
  height: 100%;
`;

export const StyledForm = styled((props) => <FormControl {...props} />)`
  margin: 5;
`;
