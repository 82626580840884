import Grid from 'src/components/Grid';
import styled from 'styled-components';

export const StyledContainer = styled((props) => <Grid {...props} />)`
  background-color: '#dbdbdb';
  padding: '20px';
  margin-bottom: '30px';
`;

export const StyledDescription = styled((props) => <Grid {...props} />)`
  display: flex;
  justify-content: flex-end;
`;
