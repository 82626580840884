import React from 'react';
import { Paper, PaperProps } from '@material-ui/core';
import styled from 'styled-components';
import { TABLE_MIN_WIDTH } from 'src/constants';

interface HeaderProps extends PaperProps {
  children: React.ReactNode;
}

export const StyledPaper = styled(
  React.forwardRef<HTMLDivElement, HeaderProps>(
    ({ children, ...props }, ref) => (
      <Paper ref={ref} {...props}>
        {children}
      </Paper>
    )
  )
)`
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  backround-color: white;
  box-shadow: none;

  @media (min-width: ${TABLE_MIN_WIDTH}px) {
    overflow-x: hidden;
  }
`;
