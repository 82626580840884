import { useMemo, useContext } from 'react';
import { Popover, Container, MenuItem } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Grid from 'src/components/Grid';
import { PopoverItems, SelectChangeEvent } from 'src/types';
import { DEFAULT_EMPTY_SLOT_VALUE } from 'src/constants';
import {
  getAllStudyHours,
  getAllStudyItems,
  getTargetStudyHours,
  setSchedule,
  switchStudyActivity,
} from 'src/utils';
import {
  StyledButton,
  StyledForm,
  StyledInput,
  StyledSelect,
  StyledPaper,
} from './PopOver.styles';
import { AppPropsContext } from 'src/context';

export default function PopOver(props: PopoverItems) {
  const { onChangeStudyPlan } = useContext(AppPropsContext);
  const {
    anchorEl,
    assignedStudy,
    id,
    studyList,
    onClose,
    onHourChange,
    open,
  } = props;

  const updateStudyStorage = () => {
    const hours = getAllStudyHours();
    const items = getAllStudyItems();
    onHourChange(hours, items);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSchedule(id, event.target.value);
    updateStudyStorage();
    onClose();
    if (assignedStudy !== event.target.value && assignedStudy !== 'free') {
      const data = switchStudyActivity({
        existingStudy: assignedStudy!,
        newStudy: event.target.value,
        hours: getTargetStudyHours(id)!,
      });
      data.map((callback) => onChangeStudyPlan(callback));
    } else {
      onChangeStudyPlan({ type: event.target.value, hours: 1, action: 'add' });
    }
  };

  const handleRemove = (event: any) => {
    const removingHours = getTargetStudyHours(id);
    setSchedule(id, DEFAULT_EMPTY_SLOT_VALUE);
    updateStudyStorage();
    onClose();
    onChangeStudyPlan({
      type: assignedStudy!,
      hours: -removingHours,
      action: 'remove',
    });
  };

  const studyItems = useMemo(
    () =>
      studyList
        .map((study) => {
          return { value: study.value, label: study.label };
        })
        .filter((item) => item.value !== DEFAULT_EMPTY_SLOT_VALUE),
    [studyList]
  );
  return (
    <Popover
      id={`pop-${id}`}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Container>
        <StyledPaper>
          <StyledInput>Study Type</StyledInput>
          <Grid container spacing={1}>
            <Grid item>
              <StyledForm variant='outlined' size='small'>
                <StyledSelect value={assignedStudy} onChange={handleChange}>
                  <MenuItem disabled value={DEFAULT_EMPTY_SLOT_VALUE}>
                    Select study type..
                  </MenuItem>
                  {studyItems.map((studyItem, index) => {
                    return (
                      <MenuItem key={index} value={studyItem.value}>
                        {studyItem.label}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              </StyledForm>
            </Grid>
            <Grid item>
              <StyledButton
                id={`pop-delete-${id}`}
                variant='outlined'
                onClick={handleRemove}
              >
                <DeleteOutlineIcon />
              </StyledButton>
            </Grid>
          </Grid>
        </StyledPaper>
      </Container>
    </Popover>
  );
}
