import { useContext, useRef } from 'react';
import { TimeTableContext } from 'src/context';
import Grid from 'src/components/Grid';
import FloatBox from './FloatBox';
import TimeTable from './TimeTable';
import TableScroller from './TableScroller';
import { StyledPaper } from './Scheduler.styles';
import { useIsMobile, useVisibility } from 'src/hooks';
import { MOBILE_BREAK_POINT, TABLE_MIN_WIDTH } from 'src/constants';

export default function Scheduler() {
  const { isWrap, isLegacy } = useContext(TimeTableContext);
  const ref = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile(MOBILE_BREAK_POINT);
  const isTableVisible = useIsMobile(TABLE_MIN_WIDTH);
  const inViewPort = useVisibility(ref, '-100px');
  return (
    <>
      <Grid mt={8} mb={5}>
        {isTableVisible && !isWrap && !isLegacy && (
          <TableScroller target={ref} />
        )}
      </Grid>
      <StyledPaper ref={ref}>
        <Grid
          mt={isMobile ? 20 : 0}
          container
          wrap={!isWrap ? 'nowrap' : 'wrap'}
          id='timeTable'
          style={{ touchAction: 'pan-y pan-x' }}
        >
          <TimeTable />
          {isMobile && inViewPort && !isLegacy && <FloatBox />}
        </Grid>
      </StyledPaper>
    </>
  );
}
