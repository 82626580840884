import { createContext, useMemo, useState } from 'react';
import { CallbackStudy, CallbackSubject, InitProps } from 'src/types';
import { MainTheme } from 'src/theme/Themes/MainTheme';
import {
  DEFAULT_STUDY_TYPES_PROP,
  DEFAULT_TIMETABLE_PROP,
} from 'src/constants/defaultProps';
import {
  hasStudyTypeUpdated,
  resetLocalStorage,
  initStudyTypesProp,
} from 'src/utils';
import { STUDY_ITEMS_STORAGE } from 'src/constants';
import useLocalStorage from 'src/hooks/use-localStorage';

const AppPropsContext = createContext({
  theme: MainTheme,
  studyTypes: DEFAULT_STUDY_TYPES_PROP,
  timeTable: DEFAULT_TIMETABLE_PROP,
  isStudyMonitored: false,
  isSubjectMonitored: false,
  onChangeStudyPlan: (data: CallbackStudy) => {},
  onChangeSubjectNum: (data: CallbackSubject) => {},
});

const AppProps = ({ children, ...props }: InitProps) => {
  const [storedStudyTypes] = useLocalStorage(STUDY_ITEMS_STORAGE);
  const theme = props.theme ? props.theme : MainTheme;
  const studyTypes =
    props.studyTypes && props.studyTypes.length > 0
      ? initStudyTypesProp(props.studyTypes)
      : DEFAULT_STUDY_TYPES_PROP;
  const timeTable = props.timeTable ? props.timeTable : DEFAULT_TIMETABLE_PROP;
  const isStudyMonitored = props.onChangeStudyPlan ? true : false;
  const isSubjectMonitored = props.onChangeSubjectNum ? true : false;

  useState(() => {
    if (storedStudyTypes !== null) {
      const hasNewStudyTypes = hasStudyTypeUpdated(
        storedStudyTypes,
        studyTypes
      );
      if (hasNewStudyTypes) {
        resetLocalStorage();
      }
    }
  });

  const onChangeStudyPlan = useMemo(
    () =>
      props.onChangeStudyPlan
        ? props.onChangeStudyPlan
        : (data: CallbackStudy) => data,
    [props.onChangeStudyPlan]
  );
  const onChangeSubjectNum = useMemo(
    () =>
      props.onChangeSubjectNum
        ? props.onChangeSubjectNum
        : (data: CallbackSubject) => data,
    [props.onChangeSubjectNum]
  );

  return (
    <AppPropsContext.Provider
      value={useMemo(
        () => ({
          theme,
          studyTypes,
          timeTable,
          isStudyMonitored,
          isSubjectMonitored,
          onChangeStudyPlan,
          onChangeSubjectNum,
        }),
        [
          theme,
          studyTypes,
          timeTable,
          isStudyMonitored,
          isSubjectMonitored,
          onChangeStudyPlan,
          onChangeSubjectNum,
        ]
      )}
    >
      {children}
    </AppPropsContext.Provider>
  );
};

export { AppPropsContext, AppProps };
