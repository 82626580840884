import {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { ChildComponent } from 'src/types';

function checkOldIos() {
  const device = navigator.userAgent;
  const matchedOs = device.match(/OS [\d_]+/i);
  const isApple = /(iPhone|iPod|iPad)/i.test(device);
  if (device && isApple && matchedOs) {
    const version = matchedOs[0]
      .substr(3)
      .split('_')
      .map((n) => parseInt(n));
    return version[0] < 13 ? true : false;
  }
  return false;
}

const TimeTableContext = createContext({
  slotSize: '',
  isWrap: false,
  isLegacy: false,
  updateSlotSize: (newSlotSize: string) => {},
  updateIsWrap: () => {},
});

const TimeTableProvider = ({ children }: ChildComponent) => {
  const [slotSize, setSlotSize] = useState<string>('100px');
  const [isWrap, setIsWrap] = useState<boolean>(false);
  const [isLegacy, setIsLegacy] = useState<boolean>(false);

  useEffect(() => {
    const isOutdated = checkOldIos();
    setIsLegacy(isOutdated);
    if (isOutdated) {
      setSlotSize('');
      setIsWrap(true);
    }
  }, []);

  const updateSlotSize = useCallback(
    (newSubjectNumb: string) => {
      setSlotSize(newSubjectNumb);
    },
    [setSlotSize]
  );
  const updateIsWrap = useCallback(() => {
    setIsWrap(!isWrap);
  }, [isWrap, setIsWrap]);

  return (
    <TimeTableContext.Provider
      value={useMemo(
        () => ({
          slotSize,
          isWrap,
          isLegacy,
          updateSlotSize,
          updateIsWrap,
        }),
        [slotSize, isWrap, isLegacy, updateSlotSize, updateIsWrap]
      )}
    >
      {children}
    </TimeTableContext.Provider>
  );
};

export { TimeTableContext, TimeTableProvider };
