import styled from 'styled-components';
import { ListProps } from 'src/types';
import {
  DEFAULT_TIMESLOT_HEIGHT,
  DEFAULT_TIMESLOT_MARGIN,
} from 'src/constants';

export const StyledItems = styled.li<ListProps>`
  display: block;
  text-align: ${(props) => props.type === 'time' && 'center'};
  margin: ${DEFAULT_TIMESLOT_MARGIN}px;
  width: ${(props) => props.slotSize};
  height: ${DEFAULT_TIMESLOT_HEIGHT}px;
  line-height: ${DEFAULT_TIMESLOT_HEIGHT}px;

  &[data-activity-id] {
    font-size: inherit;
    color: rgba(8, 8, 8, 0.37);
    text-rendering: auto;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    border-radius: 0.2em;
  }

  ${(props) => {
    const studyTypeList = props.studyItems;
    if (studyTypeList) {
      const newList = studyTypeList.map((study) => {
        return `
      &[data-activity-id=${study.value}] {
        background-color: ${study.color}
      }
    `;
      });
      return newList;
    }
  }}
`;
