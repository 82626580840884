import { Box } from '@material-ui/core';
import Grid from 'src/components/Grid';
import DynamicIcon from 'src/components/DynamicIcon';
import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const StyledCell = styled((props) => <Grid {...props} />)`
  margin-bottom: -30%;
  height: inherit;
  background-color: inherit;
`;

export const StyledCursor = styled((props) => <Typography {...props} />)`
    background-color: ${(props) => props.theme.palette.primary.main};
    bottom: 0;
    touch-action: pan-down;
    &.MuiButtonBase-root {
    padding: 9px;
    z-index: 999;
    border: ${(props) => !props.disabled && 'white solid 3px;'}
    vertical-align: ${(props) => `${props.wrap};`}
    &:hover {
        background-color: ${(props) => props.theme.palette.primary.main};
    }
    }
    ${(props) =>
      props.wrap === 'top' &&
      `@media(min-width: 700px){
      vertical-align: baseline !important;
    }
    @media(min-width: 600px) and (max-width: 700px){
      vertical-align: text-bottom !important;
    }
    @media(min-width: 700px) and (max-width: 800px){
      vertical-align: middle !important;
    }`}
`;

export const StyledHiddenTouchArea = styled((props) => (
  <Typography {...props} />
))`
  width: 100%;
  height: 40px;
  bottom: 0;
  margin-block: -40px;
  touch-action: pan-down;
  z-index: 998;
  position: relative;
`;

export const StyledDayLabel = styled((props) => (
  <Typography variant='body1' {...props} />
))`
  color: ${(props) => props.theme.palette.primary.main};
  text-align: center;
`;

export const StyledDragBox = styled((props) => <Box {...props} />)`
  display: inline-block;
  justify-content: center;
  touch-action: pan-y;
  width: 80%;
  height: 80%;
  z-index: 995;
`;

export const StyledIconInCell = styled((props) => {
  const isFree = props.type !== 'free';
  return isFree ? (
    <DynamicIcon icon={isFree ? props.icon : ''} {...props} />
  ) : (
    <div />
  );
})`
  font-size: 1.1rem;
  color: ${(props) => props.iconcolor};
`;
