import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const StyledAccordion = styled((props) => <MuiAccordion {...props} />)`
  &.MuiAccordion-root {
    box-shadow: none;
    padding: none;
    &:not(:last-child) {
      border-bottom: 0;
    }
    &:before {
      display: none;
    }
  }
  &.Mui-expanded {
    margin: auto;
  }
`;

export const StyledAccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))`
  &.MuiAccordionSummary-root {
    margin-bottom: -1px;
    min-height: 56px;

    .MuiAccordionSummary-content {
      margin: 12px 0;
    }
  }
`;

export const StyledTitle = styled((props) => <Typography {...props} />)`
  @media (max-width: 600px) {
    font-size: 0.99rem;
  }
`;

export const StyledAccordionTitle = styled((props) => (
  <Typography {...props} />
))`
  text-align: left;
`;

export const StyledAccordionDesc = styled.div`
  flex-basis: 100%;
`;

export const StyledExpandableIcon = styled((props) => (
  <ExpandMoreIcon {...props} />
))`
  color: ${(props) => props.theme.palette.primary.main};
`;

export const StyledButtonText = styled(() => (
  <Typography variant='h5' display='initial' />
))``;
