import { useContext } from 'react';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Grid from 'src/components/Grid';
import { StyledContainer, StyledFab } from './FloatBox.styles';
import { TimeTableContext } from 'src/context';
import { DEFAULT_TIMESLOT_WIDTH } from 'src/constants';

const FloatBox = () => {
  const { isWrap, updateIsWrap, updateSlotSize } = useContext(TimeTableContext);
  const onZoomSchedule = () => {
    updateIsWrap();
    updateSlotSize(isWrap ? `${DEFAULT_TIMESLOT_WIDTH}px` : '');
  };
  return (
    <Grid mb={20}>
      <StyledContainer>
        <StyledFab
          variant='extended'
          size='medium'
          color='primary'
          aria-label='add'
          onClick={onZoomSchedule}
        >
          {isWrap ? <ZoomInIcon /> : <ZoomOutIcon />}
          Zoom {isWrap ? 'In' : 'Out'}
        </StyledFab>
      </StyledContainer>
    </Grid>
  );
};

export default FloatBox;
