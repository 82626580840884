import { FC, ReactNode } from 'react';
import * as MaterialIcon from '@material-ui/icons';

export type LocalProps = {
  children: ReactNode;
  /** Material-Ui Icon name (Mui v4) */
  icon: keyof typeof MaterialIcon;
  /** Icon font size (Mui v4) */
  fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
};

const DynamicIcon: FC<LocalProps> = ({ children, icon, ...props }) => {
  const MuiIcon = MaterialIcon[icon];
  return <MuiIcon fontSize={'large'} {...props} />;
};

export default DynamicIcon;
