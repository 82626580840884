import styled from 'styled-components';

export const StyledList = styled.ul`
  list-style: none;
  padding: 0px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
`;
