import React, { useContext } from 'react';
import Grid from 'src/components/Grid';
import UnorderList from 'src/components/UnorderList';
import ListItem from 'src/components/ListItem';
import { LIST_TIME } from 'src/constants/daysLists';
import { TimeTableContext } from 'src/context';

const TimeList = () => {
  const { slotSize, isWrap } = useContext(TimeTableContext);
  return (
    <Grid item md sm xs>
      <UnorderList>
        {LIST_TIME.map((time, index) => {
          // const labelDivider = time.label.split(' ');
          return (
            <ListItem
              type='time'
              key={index}
              id={`time-${index}`}
              slotSize={isWrap ? '50px' : slotSize}
            >
              {/* <span>{!isWrap ? time.label : labelDivider[0]}</span> */}
              <span>{time.label}</span>
            </ListItem>
          );
        })}
      </UnorderList>
    </Grid>
  );
};

export default React.memo(TimeList);
