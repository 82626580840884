import React, { useContext } from 'react';
import Grid from 'src/components/Grid';
import Slider from 'src/components/Slider';
import { StyledContainer } from './StudySubjects.styles';
import {
  StyledSubTitle,
  StyledSubHeading,
} from 'src/features/Header-Items/Header.styles';
import { DEFAULT_HOURS_PER_SUBJECT, DEFAULT_SUBJECT_NUM } from 'src/constants';
import { PlannerContext, AppPropsContext } from 'src/context';

export default React.memo(function StudySubjects() {
  const { subjectNumb, addSubject } = useContext(PlannerContext);
  const { onChangeSubjectNum } = useContext(AppPropsContext);
  const onSubjectChange = (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => {
    if (subjectNumb !== value && value > 0) {
      const changedHours = (value as number) - subjectNumb;
      onChangeSubjectNum({
        subjects: changedHours,
        requiredHours: changedHours * DEFAULT_HOURS_PER_SUBJECT,
      });
      addSubject(value as number);
    }
  };

  return (
    <StyledContainer>
      <Grid item>
        <StyledSubTitle variant='subtitle1'>
          How many subjects are you currently studying?
        </StyledSubTitle>
      </Grid>
      <Grid container spacing={2} mb={10}>
        <Grid item>
          <StyledSubHeading variant='h3'>{subjectNumb}</StyledSubHeading>
        </Grid>
        <Grid item style={{ display: 'flex' }}>
          <StyledSubHeading variant='h5'>
            subject{subjectNumb > 1 ? 's' : ''}
          </StyledSubHeading>
        </Grid>
      </Grid>
      <Grid mb={30}>
        <Slider
          data-testid='subjectSlider'
          color='primary'
          height={20}
          max={3}
          onChange={onSubjectChange}
          thumbsize={40}
          value={subjectNumb ? subjectNumb : DEFAULT_SUBJECT_NUM}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <StyledSubHeading variant='h5'>
            {(subjectNumb as number) * DEFAULT_HOURS_PER_SUBJECT}
          </StyledSubHeading>
        </Grid>
        <Grid item>
          <StyledSubHeading variant='h6'>
            hours p/w of study needed
          </StyledSubHeading>
        </Grid>
      </Grid>
    </StyledContainer>
  );
});
