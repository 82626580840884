import StyledSlider from './Slider.styles';
import { SliderProps, SliderTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export interface LocalProps extends Omit<SliderProps, 'onChange'> {
  /** Slider's rail height value for overriding MuiSlider-rail(v4) */
  height?: number;
  /** Slider's thumb value for overriding MuiSlider-thumb(v4) */
  thumbsize?: number;
  label?: string;
}

const Slider: OverridableComponent<SliderTypeMap<LocalProps, 'span'>> = (
  props: LocalProps
) => {
  const { height, thumbsize } = props;
  return <StyledSlider height={height} thumbsize={thumbsize} {...props} />;
};

export default Slider;
