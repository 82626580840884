import { getElement } from '../elements/element-helper';
import {
  DAY_PROPERTY,
  DEFAULT_EMPTY_SLOT_VALUE,
  DEFAULT_TIMESLOT_HEIGHT,
  DEFAULT_TIMESLOT_MARGIN,
  DEFAULT_TIMESLOT_SELECTED_COLOR,
  STUDY_TYPE_PROPERTY,
} from 'src/constants';

const totalSlotHeight = DEFAULT_TIMESLOT_HEIGHT + DEFAULT_TIMESLOT_MARGIN;

export const getTargetStudyHeight = (target: HTMLElement) => {
  const isInherit = target.style.height === 'inherit';
  const targetHeight = isInherit
    ? DEFAULT_TIMESLOT_HEIGHT
    : Number(target.style.height.split('px')[0]);
  return targetHeight < totalSlotHeight
    ? DEFAULT_TIMESLOT_HEIGHT
    : targetHeight;
};

export const getTargetStudyHours = (target: string) => {
  const targetId = target.includes('grid') ? target : `${target}-grid`;
  const targetElement = getElement(targetId);
  if (targetElement) {
    return Math.floor(
      Number(targetElement.style.height.split('px')[0]) /
        DEFAULT_TIMESLOT_HEIGHT
    );
  } else {
    return 0;
  }
};

export const getAllStudyHours = () => {
  let studyHours: number = 0;
  getAllStudyItems().map((study: any) => {
    return (studyHours = Math.floor(
      studyHours + study.size / DEFAULT_TIMESLOT_HEIGHT
    ));
  });
  return studyHours;
};

export const getAllStudyItems = () => {
  let studyItems: any = [];
  DAY_PROPERTY.map((item) => {
    const dayStudyItem = getDaySchedules(item.day).filter(
      (study) => study.task !== DEFAULT_EMPTY_SLOT_VALUE
    );
    return (studyItems = [...studyItems, ...dayStudyItem]);
  });
  return studyItems;
};

export const getDaySchedules = (day: string) => {
  const checkList = Array.from(Array(24).keys());
  return checkList.map((item) => {
    const elementId = `${day}-${item < 10 ? `0${item}` : `${item}`}`;
    const element = getElement(elementId);
    const studyElement = getElement(`${elementId}-grid`);
    const targetHeight = getTargetStudyHeight(studyElement!);
    return {
      id: element?.id,
      time: item < 10 ? `0${item}` : `${item}`,
      task: element?.getAttribute(STUDY_TYPE_PROPERTY),
      size: targetHeight,
    };
  });
};

export const getNextTimeId = (targetId: string) => {
  const prevTimeId = targetId.split('-');
  const prevTime = Number(prevTimeId[1]) + 1;
  return `${prevTimeId[0]}-${prevTime < 10 ? `0${prevTime}` : prevTime}`;
};

export const getSchedule = (targetId: string) => {
  return {
    assignedSchedule: getElement(targetId)?.getAttribute(STUDY_TYPE_PROPERTY),
    assignedHours: Math.floor(
      Number(getElement(`${targetId}-grid`)?.style.height.split('px')[0]) /
        DEFAULT_TIMESLOT_HEIGHT
    ),
  };
};

export const getScheduleMaxSize = (targetId: string) => {
  const splitId = targetId.split('-');
  const daySchedule = getDaySchedules(splitId[0]);
  const futureSchedules = daySchedule.filter(
    (item) =>
      item.task !== DEFAULT_EMPTY_SLOT_VALUE &&
      Number(item.time) > Number(splitId[1])
  );
  const endScheduleNum =
    futureSchedules.length > 0 ? Number(futureSchedules[0].time) : 24;
  return {
    maxHeight: `${(endScheduleNum - Number(splitId[1])) * totalSlotHeight}px`,
    maxHours: endScheduleNum - Number(splitId[1]),
  };
};

export const setHours = (targetId: string, size: number) => {
  const target = getElement(targetId);
  if (target) {
    target.style.position = 'relative';
    target.style.height = `${size}px`;
  }
};

export const setSchedule = (targetId: string, schedule: string) => {
  const target = getElement(targetId);
  if (schedule === DEFAULT_EMPTY_SLOT_VALUE) {
    const targetGrid = getElement(`${targetId}-grid`);
    targetGrid!.style.height = 'inherit';
    targetGrid!.style.zIndex = '';
  }
  return target?.setAttribute(STUDY_TYPE_PROPERTY, schedule);
};

export const setScheduleActive = (targetId: string, isActive: boolean) => {
  const target = getElement(targetId);
  if (target) {
    return (target.style.border = isActive
      ? `solid 2px ${DEFAULT_TIMESLOT_SELECTED_COLOR}`
      : '');
  }
};

export const setStudyActivity = (target: HTMLElement, initHour: Number) => {
  const currentHeight = target.style.height;
  if (Number(currentHeight.split('px')[0]) >= DEFAULT_TIMESLOT_HEIGHT) {
    const split = target.id.split('-');
    const assignedStudy = getElement(`${split[0]}-${split[1]}`)?.getAttribute(
      'data-activity-id'
    );
    const updatedHours =
      currentHeight === 'inherit'
        ? 0
        : Math.floor(
            Number(currentHeight.split('px')[0]) / DEFAULT_TIMESLOT_HEIGHT
          );
    let action;
    const changedHours = updatedHours - Number(initHour);
    if (updatedHours > initHour) {
      action = 'add';
    } else if (updatedHours < initHour) {
      action = 'reduced';
    }
    return changedHours !== 0
      ? {
          type: assignedStudy,
          hours: updatedHours - Number(initHour),
          action: action,
        }
      : {};
  }
  return {};
};

export const switchStudyActivity = ({
  existingStudy,
  newStudy,
  hours,
}: {
  existingStudy: string;
  newStudy: string;
  hours: number;
}) => {
  return [
    { type: existingStudy, hours: -hours, action: 'remove' },
    { type: newStudy, hours: hours, action: 'add' },
  ];
};
