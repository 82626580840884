import React, { useContext } from 'react';
import { PlannerContext } from 'src/context';
import Grid from 'src/components/Grid';
import ProgressBar from 'src/components/ProgressBar';
import { StyledContainer, StyledDescription } from './StudyHours.styles';
import { DEFAULT_HOURS_PER_SUBJECT } from 'src/constants';
import {
  StyledSubTitle,
  StyledBody,
} from 'src/features/Header-Items/Header.styles';

export default React.memo(function StudyHours() {
  const { subjectNumb, studyHours } = useContext(PlannerContext);
  const maxStudyHours = subjectNumb * DEFAULT_HOURS_PER_SUBJECT;
  const progressHours =
    studyHours < maxStudyHours
      ? Math.ceil((studyHours / maxStudyHours) * 100)
      : 100;

  return (
    <StyledContainer>
      <Grid item>
        <StyledSubTitle variant='subtitle1'>
          Plan out this study over a week
        </StyledSubTitle>
      </Grid>
      <Grid item mb={20}>
        <StyledBody>
          Select blocks on the week view below to add your study times.
        </StyledBody>
      </Grid>
      <Grid item>
        <ProgressBar height={20} value={progressHours} variant='determinate' />
      </Grid>
      <StyledDescription item>
        <StyledBody>
          {`${studyHours} / ${maxStudyHours} hour${
            studyHours > 1 ? 's' : ''
          } added`}
        </StyledBody>
      </StyledDescription>
    </StyledContainer>
  );
});
