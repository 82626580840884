//Local storage names
export const SUBJECT_STORAGE = 'planner-subject-num';
export const STUDY_HOURS_STORAGE = 'planner-study-hours';
export const STUDY_ITEMS_STORAGE = 'planner-study-items';

//App default values
export const DEFAULT_SUBJECT_NUM = 1;
export const DEFAULT_HOURS_PER_SUBJECT = 15;
export const DEFAULT_OLD_IOS_VERSION = 13;

//App elements default values
export const MIN_APP_SIZE = '400px';
export const TABLE_MIN_WIDTH = 950;
export const MOBILE_BREAK_POINT = 765;
export const OVERFLOW_HIDDEN = 'hidden';
export const OVERFLOW_SCROLL = 'scroll';
export const STUDY_TYPE_PROPERTY = 'data-activity-id';

//Time table default values
export const DEFAULT_EMPTY_SLOT_VALUE = 'free';
export const DEFAULT_TITMETALBE_MAXSIZE = 'md';
export const DEFAULT_TIMESLOT_WIDTH = 100;
export const DEFAULT_TIMESLOT_HEIGHT = 45;
export const DEFAULT_TIMESLOT_MARGIN = 5;
export const DEFAULT_TIMESLOT_ZINDEX = 1;
export const DEFAULT_TIMESLOT_SELECTED_COLOR = '#6AA2EB';

//`${DEFAULT_TIMESLOT_HEIGHT}px`
