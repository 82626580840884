import { ThemeGenerator } from 'src/theme';
import { AppProps } from './context';
import StudyPlanner from 'src/features/StudyPlanner';
import { InitProps } from './types';

function App(props: InitProps) {
  return (
    <AppProps {...props}>
      <ThemeGenerator>
        <StudyPlanner />
      </ThemeGenerator>
    </AppProps>
  );
}
export default App;
