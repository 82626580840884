import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const StyledBody = styled((props) => (
  <Typography variant='body2' {...props} />
))`
  color: gray;
  font-size: 16px;
  text-align: left;
`;
StyledBody.displayName = 'StyledBody';

export const StyledSubHeading = styled((props) => <Typography {...props} />)`
  margin-bottom: 5%;
  align-items: end;
  display: flex;
`;
StyledSubHeading.displayName = 'StyledSubHeading';

export const StyledSubTitle = styled((props) => <Typography {...props} />)`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 0.99rem;
  }
`;
StyledSubTitle.displayName = 'StyledSubTitle';

export const StyledTitle = styled((props) => (
  <Typography variant='h3' {...props} />
))`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
`;
