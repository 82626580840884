import Grid from 'src/components/Grid';
import ReadMore from './ReadMore';
import StudySubjects from './StudySubjects';
import StudyHours from './StudyHours';
import { StyledTitle, StyledBody } from './Header.styles';
import { Divider } from '@material-ui/core';

export default function Header() {
  return (
    <Grid>
      <Grid item>
        <StyledTitle>Study planner</StyledTitle>
      </Grid>
      <Grid item mb={30}>
        <StyledBody>
          This tool helps you plan your weekly study routine based on the number
          of subjects you are currently enrolled in.
        </StyledBody>
      </Grid>
      <Grid item mb={30}>
        <StyledBody>
          Add the required hours to the week below to plan out your study.
        </StyledBody>
      </Grid>
      <Grid item mb={30}>
        <Divider />
        <ReadMore />
        <Divider />
      </Grid>
      <Grid item mb={40}>
        <StudySubjects />
      </Grid>
      <Grid item mb={40}>
        <StudyHours />
      </Grid>
    </Grid>
  );
}
