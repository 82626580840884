import Header from './Header-Items/Header';
import Scheduler from './Scheduler-Items/Scheduler';
import { PlannerProvider, TimeTableProvider } from 'src/context';
import { DEFAULT_TITMETALBE_MAXSIZE } from 'src/constants';
import { StyledContainer } from './StudyPlanner.styles';
export default function StudyPlanner() {
  return (
    <StyledContainer maxWidth={DEFAULT_TITMETALBE_MAXSIZE}>
      <PlannerProvider>
        <Header />
        <TimeTableProvider>
          <Scheduler />
        </TimeTableProvider>
      </PlannerProvider>
    </StyledContainer>
  );
}
