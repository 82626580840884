import { getNextTimeId } from '../scheduler/scheduler-helper';
import {
  DEFAULT_TIMESLOT_HEIGHT,
  DEFAULT_TIMESLOT_MARGIN,
} from 'src/constants';

const totalSlotMargin = DEFAULT_TIMESLOT_HEIGHT + DEFAULT_TIMESLOT_MARGIN;

export const getElement = (targetId: string) =>
  document.getElementById(targetId);

export const getAssignedStudy = (targetId: string) => {
  return getElement(targetId)
    ? getElement(targetId)?.getAttribute('data-activity-id')
    : 'free';
};

export const dragButton = (targetId: string) => {
  const targetActivity = getElement(targetId)?.getAttribute('data-activity-id');
  const dayForwardActivity = getElement(getNextTimeId(targetId))?.getAttribute(
    'data-activity-id'
  );
  return targetActivity !== 'free'
    ? targetActivity === dayForwardActivity
    : true;
};

export const resizeMobileSchedule = ({
  e,
  target,
  currentHeight,
  mouseY,
}: {
  e: MouseEvent | TouchEvent;
  target: string;
  currentHeight: string;
  mouseY: number;
}) => {
  const targetElement = getElement(target);
  e.stopPropagation();
  const initHeight =
    currentHeight === 'inherit'
      ? DEFAULT_TIMESLOT_HEIGHT
      : Number(currentHeight);
  let updatedHeight = 1;
  if (window.TouchEvent && e instanceof TouchEvent) {
    updatedHeight =
      initHeight + (Math.ceil(e.targetTouches[0].pageY) - Math.ceil(mouseY));
  } else if (e instanceof MouseEvent) {
    updatedHeight = initHeight + (Math.ceil(e.pageY) - Math.ceil(mouseY));
  }

  if (updatedHeight % totalSlotMargin > 19) {
    targetElement!.style.height = `${
      updatedHeight +
      (totalSlotMargin -
        (updatedHeight % totalSlotMargin) -
        DEFAULT_TIMESLOT_MARGIN)
    }px`;
  } else if (updatedHeight % totalSlotMargin < 13) {
    targetElement!.style.height = `${
      updatedHeight -
      (updatedHeight % totalSlotMargin) +
      DEFAULT_TIMESLOT_MARGIN
    }px`;
  } else {
    targetElement!.style.height = `${updatedHeight}px`;
  }
};

export const cleanUpSlotSize = (target: HTMLElement) => {
  if (target) {
    const currentSlotHeight = Number(target.style.height.split('px')[0]);

    let filledSlotsNum = Math.floor(
      currentSlotHeight / DEFAULT_TIMESLOT_HEIGHT
    );
    filledSlotsNum =
      filledSlotsNum / 10 > 1
        ? filledSlotsNum - Math.floor(filledSlotsNum / 10)
        : filledSlotsNum;

    const expectedSlotHeight = (slotNumb: number) =>
      slotNumb * DEFAULT_TIMESLOT_HEIGHT +
      (slotNumb - 1) * DEFAULT_TIMESLOT_MARGIN;

    if (currentSlotHeight !== expectedSlotHeight(filledSlotsNum)) {
      target.style.height = `${
        expectedSlotHeight(filledSlotsNum) > currentSlotHeight
          ? expectedSlotHeight(filledSlotsNum - 1)
          : expectedSlotHeight(filledSlotsNum)
      }px`;
    }

    // const currentSlotHeight = Number(target.style.height.split('px')[0]);

    // const marginOffset =
    //   (DEFAULT_TIMESLOT_HEIGHT / DEFAULT_TIMESLOT_MARGIN - 1) *
    //   DEFAULT_TIMESLOT_HEIGHT;

    // const totalMarginValue =
    //   Math.floor(currentSlotHeight / marginOffset) * DEFAULT_TIMESLOT_HEIGHT;

    // const fixedHeight =
    //   currentSlotHeight > marginOffset
    //     ? currentSlotHeight - totalMarginValue
    //     : currentSlotHeight;

    // console.log('marginOffset - ', marginOffset);
    // console.log(
    //   'currentSlotHeight > marginOffset ? ',
    //   currentSlotHeight > marginOffset
    // );
    // const filledSlotsNum = Math.floor(fixedHeight / DEFAULT_TIMESLOT_HEIGHT);

    // const expectedSlotHeight =
    //   filledSlotsNum * DEFAULT_TIMESLOT_HEIGHT +
    //   (filledSlotsNum - 1) * DEFAULT_TIMESLOT_MARGIN;

    // console.log('currentSlotHeight - ', currentSlotHeight);
    // console.log('fixedHeight', fixedHeight);
    // console.log('filledSlotsNum - ', filledSlotsNum);
    // console.log('expectedSlotHeight', expectedSlotHeight);
    // console.log('totalMarginValue', totalMarginValue);
    // console.log(
    //   'expectedSlotHeight with margin ',
    //   expectedSlotHeight + totalMarginValue + 5
    // );

    // if (
    //   currentSlotHeight !==
    //   expectedSlotHeight + DEFAULT_TIMESLOT_HEIGHT + DEFAULT_TIMESLOT_MARGIN
    // ) {
    //   target.style.height = `${expectedSlotHeight}px`;
    // }
  }
};
