import React from 'react';
import StyledTypography from './Typography.styles';

type TypoProps = {
  children?: React.ReactNode;
  variant?: string;
};
const Typography = ({ children, variant, ...typographyProps }: TypoProps) => {
  return (
    <StyledTypography variant={variant} {...typographyProps}>
      {children}
    </StyledTypography>
  );
};

Typography.defaultProps = {
  variant: 'body1',
  display: 'initial',
};

export default Typography;
