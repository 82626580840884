export const LIST_TIME = [
  { label: '12 am' },
  { label: '1 am' },
  { label: '2 am' },
  { label: '3 am' },
  { label: '4 am' },
  { label: '5 am' },
  { label: '6 am' },
  { label: '7 am' },
  { label: '8 am' },
  { label: '9 am' },
  { label: '10 am' },
  { label: '11 am' },
  { label: '12 pm' },
  { label: '1 pm' },
  { label: '2 pm' },
  { label: '3 pm' },
  { label: '4 pm' },
  { label: '5 pm' },
  { label: '6 pm' },
  { label: '7 pm' },
  { label: '8 pm' },
  { label: '9 pm' },
  { label: '10 pm' },
  { label: '11 pm' },
  { label: '12 am' },
];

export const LIST_DAYS = (day: string) => {
  return [
    { id: `${day}-00`, day: day, time: '00', activity: 'free' },
    { id: `${day}-01`, day: day, time: '01', activity: 'free' },
    { id: `${day}-02`, day: day, time: '02', activity: 'free' },
    { id: `${day}-03`, day: day, time: '03', activity: 'free' },
    { id: `${day}-04`, day: day, time: '04', activity: 'free' },
    { id: `${day}-05`, day: day, time: '05', activity: 'free' },
    { id: `${day}-06`, day: day, time: '06', activity: 'free' },
    { id: `${day}-07`, day: day, time: '07', activity: 'free' },
    { id: `${day}-08`, day: day, time: '08', activity: 'free' },
    { id: `${day}-09`, day: day, time: '09', activity: 'free' },
    { id: `${day}-10`, day: day, time: '10', activity: 'free' },
    { id: `${day}-11`, day: day, time: '11', activity: 'free' },
    { id: `${day}-12`, day: day, time: '12', activity: 'free' },
    { id: `${day}-13`, day: day, time: '13', activity: 'free' },
    { id: `${day}-14`, day: day, time: '14', activity: 'free' },
    { id: `${day}-15`, day: day, time: '15', activity: 'free' },
    { id: `${day}-16`, day: day, time: '16', activity: 'free' },
    { id: `${day}-17`, day: day, time: '17', activity: 'free' },
    { id: `${day}-18`, day: day, time: '18', activity: 'free' },
    { id: `${day}-19`, day: day, time: '19', activity: 'free' },
    { id: `${day}-20`, day: day, time: '20', activity: 'free' },
    { id: `${day}-21`, day: day, time: '21', activity: 'free' },
    { id: `${day}-22`, day: day, time: '22', activity: 'free' },
    { id: `${day}-23`, day: day, time: '23', activity: 'free' },
  ];
};

export const DAY_PROPERTY = [
  { day: 'monday', label: 'Mon', position: 0 },
  { day: 'tuesday', label: 'Tue', position: 1 },
  { day: 'wednesday', label: 'Wed', position: 2 },
  { day: 'thursday', label: 'Thu', position: 3 },
  { day: 'friday', label: 'Fri', position: 4 },
  { day: 'saturday', label: 'Sat', position: 5 },
  { day: 'sunday', label: 'Sun', position: 6 },
];
