import { Box, Fab } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContainer = styled((props) => <Box {...props} />)`
    display: flex;
    justify-content: 'center',
    & > :not(style) {
        margin: 1;
    }
`;

export const StyledFab = styled((props) => <Fab {...props} />)`
  position: fixed;
  width: 45% !important;
  bottom: 5%;
  text-transform: none;
  left: 30%;
  z-index: 999;
`;
