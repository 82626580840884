import {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { ChildComponent } from 'src/types';
import { SUBJECT_STORAGE, STUDY_HOURS_STORAGE } from 'src/constants';
import useLocalStorage from 'src/hooks/use-localStorage';

const PlannerContext = createContext({
  subjectNumb: 1,
  studyHours: 0,
  addSubject: (newSubjectNumb: number) => {},
  addHours: (newSubjectHours: number) => {},
});

const PlannerProvider = ({ children }: ChildComponent) => {
  const [storedSubjectNum, setStoredSubjectNum] =
    useLocalStorage(SUBJECT_STORAGE);
  const [storedSubjectHours, setStoredSubjectHours] =
    useLocalStorage(STUDY_HOURS_STORAGE);

  useEffect(() => {
    storedSubjectNum && setSubjectNumb(storedSubjectNum);
    storedSubjectHours && setStudyHours(storedSubjectHours);
  }, []);

  const [subjectNumb, setSubjectNumb] = useState<number>(1);
  const [studyHours, setStudyHours] = useState<number>(0);

  const addSubject = useCallback((newSubjectNumb: number) => {
    const updatedNumb = newSubjectNumb === 0 ? 1 : newSubjectNumb;
    setStoredSubjectNum(updatedNumb);
    setSubjectNumb(updatedNumb);
  }, []);
  const addHours = useCallback((newSubjectHours: number) => {
    setStoredSubjectHours(newSubjectHours);
    setStudyHours(newSubjectHours);
  }, []);

  return (
    <PlannerContext.Provider
      value={useMemo(
        () => ({
          subjectNumb,
          studyHours,
          addSubject,
          addHours,
        }),
        [subjectNumb, studyHours, addSubject, addHours]
      )}
    >
      {children}
    </PlannerContext.Provider>
  );
};

export { PlannerContext, PlannerProvider };
