import { LinearProgress } from '@material-ui/core';
import styled from 'styled-components';

export default styled((props) => <LinearProgress {...props} />)`
  ${(props) => {
    const { height } = props;
    return `
            &.MuiLinearProgress-root {
                height: ${height !== undefined ? `${height}px` : '10px'}
            }
            margin-bottom: 10px;
        `;
  }}
`;
